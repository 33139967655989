import { createReducer, on } from '@ngrx/store';
import { MembershipRenewState, MobileRenewFormAction } from './mobile-renew.models';
import { MobileRenewActions } from './mobile-renew.actions';
import { RequestStatus } from '../../../types/request-status';

export const initialState: MembershipRenewState = {
  formAction: MobileRenewFormAction.Empty,
  validationRequestStatus: RequestStatus.UNSENT,
  validationError: null,
  executionData: null,
  executionRequestStatus: RequestStatus.UNSENT,
  executionError: null,
  accountDetails: null,
};

export const mobileRenewReducer = createReducer(
  initialState,

  on(MobileRenewActions.setFormAction, (state, { formAction }) => ({ ...state, formAction })),
  on(MobileRenewActions.recostValidateRenew, MobileRenewActions.recostValidateUpgrade, (state) => ({
    ...state,
    validationRequestStatus: RequestStatus.RUNNING,
    validationError: null,
    executionData: null,
  })),
  on(MobileRenewActions.recostValidateSucceeded, (state, { executionData }) => ({
    ...state,
    executionData,
    validationError: null,
    validationRequestStatus: RequestStatus.SUCCESS,
  })),
  on(MobileRenewActions.recostValidateFailed, (state, { error }) => ({
    ...state,
    validationError: error,
    validationRequestStatus: RequestStatus.FAILED,
    executionData: '',
  })),
  on(MobileRenewActions.pay, (state) => ({
    ...state,
    executionRequestStatus: RequestStatus.RUNNING,
    executionError: null,
  })),
  on(MobileRenewActions.paySucceeded, (state) => ({
    ...state,
    executionError: null,
    executionRequestStatus: RequestStatus.SUCCESS,
  })),
  on(MobileRenewActions.payFailed, (state, { error }) => ({
    ...state,
    executionError: error,
    executionRequestStatus: RequestStatus.FAILED,
  })),
  on(MobileRenewActions.setAccountDetails, (state, { accountDetails }) => ({ ...state, accountDetails })),
  on(MobileRenewActions.reset, () => ({ ...initialState })),
);
