import { ConnectSuiteMembershipLevel } from '@aaa/interface-joinRenew-membership-membershipConnectSuite';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { getClubOption } from '@aaa/emember/store-membership';
import { RequestStatus } from '../../../types/request-status';
import { MembershipRenewState, MobileQueryParams } from './mobile-renew.models';
import { getRouterQueryParams } from '../router/router.selectors';
import { AccountDetailsSelectors } from '../../modules/share/ngrx-helpers/account-details.selectors';
import { getErrorMessage } from '../generic-errors';

export const MOBILE_RENEW_FEATURE_KEY = 'mobile-renew';

export const getMobileRenewFormState = createFeatureSelector<MembershipRenewState>(MOBILE_RENEW_FEATURE_KEY);

export const getMobileRenewFormAction = createSelector(getMobileRenewFormState, ({ formAction }) => formAction);

export const getMobileRenewExecutionData = createSelector(
  getMobileRenewFormState,
  ({ executionData }) => executionData,
);
export const getMobileRenewExecutionRequestStatus = createSelector(
  getMobileRenewFormState,
  ({ executionRequestStatus }) => executionRequestStatus,
);
export const getMobileRenewIsLoadingExecution = createSelector(
  getMobileRenewExecutionRequestStatus,
  (status) => status === RequestStatus.RUNNING,
);

export const getMobileRenewValidationRequestStatus = createSelector(
  getMobileRenewFormState,
  ({ validationRequestStatus }) => validationRequestStatus,
);
export const getMobileRenewIsLoadingValidation = createSelector(
  getMobileRenewValidationRequestStatus,
  (status) => status === RequestStatus.RUNNING,
);

export const getMobileRenewAccountDetails = createSelector(
  getMobileRenewFormState,
  ({ accountDetails }) => accountDetails,
);
export const MembershipMobileRenewAccount = AccountDetailsSelectors(getMobileRenewAccountDetails, getClubOption);

//
export const getMobileRenewErrorMessage = createSelector(
  getMobileRenewFormState,
  getClubOption,
  ({ executionError, validationError }, option) => {
    return getErrorMessage(executionError || validationError, option);
  },
);

export const getMobileRenewQuery = createSelector(getRouterQueryParams, (queryParams) => {
  return {
    appId: queryParams['appid'] || queryParams['appId'] || '',
    redirectUri: queryParams['redirect_uri'] || '',
    level: (queryParams['level'] && (queryParams['level'].toUpperCase() as ConnectSuiteMembershipLevel)) || null,
    rv: queryParams['rv'] === 'true',
    autoRenew: queryParams['autorenew'] === 'true',
  } as MobileQueryParams;
});
