import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { AccountDetails, ValidateSucceededResponseObject } from '../types/types';
import { RequestError } from '../generic-errors';
import {
  ErrorCode,
  MobileRenewFormAction,
  MobileRenewPayParams,
  RecostChangeLevelParams,
  RecostRenewParams,
  StatusResponse,
} from './mobile-renew.models';

export const MobileRenewActions = createActionGroup({
  source: 'Mobile Renew',
  events: {
    'navigate To': props<{ status: StatusResponse; errorCode?: ErrorCode }>(),
    'Set Form Action': props<{ formAction: MobileRenewFormAction }>(),
    'Recost Validate Upgrade': props<{ params: RecostChangeLevelParams }>(),
    'Recost Validate Renew': props<{ params: RecostRenewParams }>(),
    'Recost Validate Succeeded': props<ValidateSucceededResponseObject>(),
    'Recost Validate Failed': props<{ error: RequestError }>(),
    validate: props<{ params: MobileRenewPayParams }>(),
    Pay: props<{ params: MobileRenewPayParams }>(),
    'Pay Succeeded': emptyProps(),
    'Pay Failed': props<{ error: RequestError }>(),
    'Set Account Details': props<{ accountDetails: AccountDetails }>(),
    Reset: emptyProps(),
  },
});
