import { FormControl, FormGroup } from '@angular/forms';
import { AccountDetails } from '../types/types';
import { RequestStatus } from '../../../types/request-status';
import { FormGroupValue } from '../../modules/share/form.utils';
import { RequestError } from '../generic-errors';
import {
  ConnectSuiteMembershipLevel,
  MembershipConnectSuiteRecostValidateChangeLevelEventPayload,
} from '@aaa/interface-joinRenew-membership-membershipConnectSuite';
import { PaymentCardHolderForm } from '../../../types/payment-card-holder';

export type AppId = 'iosmobile' | 'androidmobile';

export type MobileQueryParams = {
  appId?: AppId;
  redirectUri?: string;
  level: ConnectSuiteMembershipLevel | null;
  rv: boolean;
  autoRenew: boolean;
};

export type MembershipRenewForm = {
  paymentCardHolder: FormGroup<PaymentCardHolderForm>;
  autoRenew: FormControl<boolean>;
};

export enum MobileRenewFormAction {
  RENEW = 'renew',
  UPGRADE = 'upgrade',
  Empty = '',
}

export type MembershipRenewState = {
  formAction: MobileRenewFormAction;
  validationRequestStatus: RequestStatus;
  validationError: RequestError | null;
  executionData: any;
  executionRequestStatus: RequestStatus;
  executionError: RequestError | null;
  accountDetails: AccountDetails | null;
};

export type RecostChangeLevelParams = {
  newLevel: MembershipConnectSuiteRecostValidateChangeLevelEventPayload['newLevel'];
  autoRenew: MembershipConnectSuiteRecostValidateChangeLevelEventPayload['autoRenew'];
  memberNumber: MembershipConnectSuiteRecostValidateChangeLevelEventPayload['memberNumber'];
};

export type RecostRenewParams = {
  autoRenew: boolean;
  accountDetails: AccountDetails;
};

export type MobileRenewPayParams = {
  formValues: FormGroupValue<MembershipRenewForm>;
  accountDetails: AccountDetails;
  executionData: string;
  totalCost: number;
};

//status
export type StatusResponse = 'success' | 'error' | 'goBack';

//errorCode
export type ErrorCode =
  | 'canceled'
  | 'paymentNotProcessed'
  | 'membershipLookUpError'
  | 'membershipNotProcessed'
  | 'membershipIsNotPendingStatus'
  | 'membershipIsCanceled'
  | 'membershipInvalidLevel';
